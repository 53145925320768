<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
import InputGroup from "@/components/forms/input-group";
import SaveButton from "@/components/buttons/save-button";
import StatusGroup from "@/components/forms/status-group";

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        InputGroup,
        SaveButton,
        StatusGroup
    },
    data() {
        const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

        return {
            id: id,
            title: `Amenity ${modeName}`,
            modeName,
            items: [
                {
                    text: "Amenity"
                },
                {
                    text: modeName,
                    active: true,
                },
            ],

            formData: {
                title: '',
                status: 1,
                is_countable: 1
            },
            statusOptions: [
                { name: 'Active', value: 1 },
                { name: 'Inactive', value: 0 },
            ],
            pageRedirection: false,
            errors: {},
            btn_click: false
        };
    },
    watch: {
    },
    
    async mounted() {
        if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/amenity/${this.id}`, {
                headers: authHeader(),
            });

            if (result && result.data['code'] === 'success') { 
                const data = result.data.data.amenity;
                this.formData.title = data.title;
                this.formData.status = data.status;
                this.formData.is_countable = data.is_countable;
            }
        }
    },
    methods: {
        async save() {
            this.errors = {};
            if (this.btn_click) return;
            this.btn_clicked = true;
            let result;
            if (this.modeName === 'Add') {
                const requestData = this.formData;
                result = await axios.post(`${process.env.VUE_APP_APIURL}/amenity`, requestData, {
                    headers: authHeader(),
                });
            } else {
                result = await axios.put(`${process.env.VUE_APP_APIURL}/amenity/${this.id}`, this.formData, {
                    headers: authHeader(),
                });
            }

            if (result) {
                this.btn_clicked = false;
                if (result.data['code'] === 'success') {
                    if (this.modeName === 'Add') {
                        this.$router.back();
                    } else {
                        this.$refs.messageModal.showModal('Your record has been updated successfully');
                        this.pageRedirection = true;
                    }
                } else if (result.data['code'] === 'permission_denied') {
                    this.$refs.messageModal.showModal('Permission Denied');
                } else if (result.data['code'] === 'invalid_field') {
                    this.errors = result.data['errors'];
                }
            }
        },
        redirectPage() {
            if (this.pageRedirection) {
                this.$router.push({name: "Amenity"});
            }
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" @CloseBtn="redirectPage()"/>
        <div class="card p-3">
            <div class="row no-gutters">
                <div class="col-12">
                    <h4 class="subTitle-font mr-2">
                        Amenity Details
                    </h4>
                </div>

                <div class="col-12 col-md-6 col-lg-4 pl-2">
                    <InputGroup type="text" id="name" displayName="Name" v-model="formData.title" :error="errors.title" />
                </div>

                <div class="col-12 col-md-6 col-lg-4 pl-2">
                    <StatusGroup  v-model="formData.status" :allSelection="false" :statusVal="formData.status" :error="errors.status"/>
                </div>
                
                <div class="col-12 col-md-6 col-lg-4 pl-2">
                    <div class="form-group">
                        <div class="d-flex align-items-center flex-wrap">
                            <label class="mb-0 mr-3 d-block w-100">Is this a countable item?</label>
                            <div class="d-flex mt-3">
                                <input type="radio" id="yes-countable" value="1" name="countable" role="button" v-model="formData.is_countable">  
                                <label class="mb-0 ml-2 mr-3" for="yes-countable" role="button">Yes</label>
                                <input type="radio" id="no-countable" value="0" name="countable" role="button" v-model="formData.is_countable">  
                                <label class="mb-0 ml-2 mr-3" for="no-countable" role="button">No</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2 pl-2">
                <div class="col-12">
                    <SaveButton @click="save" />
                </div>
            </div>
        </div>
    </Layout>
</template>